import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const adminPage = props => (
  <Layout {...props}>
    <Seo title='Admin' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <Button variant='primary' as={Link} to='https://be.contentful.com/login'>
            Aller vers Admin
          </Button>
        </Section>
      </Main>
    </Stack>
  </Layout>
)

export default adminPage
